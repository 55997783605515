
import React from "react";
import { Button } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

interface HeaderProps {
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  }
  
  const Header: React.FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
  return (
    <header
      style={{
        padding: "10px",
        backgroundColor: "white",
        position: "sticky",
        width: "100%",
        top: 0,
        zIndex: 1,
        height: 70,
        boxShadow:
          "0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)",
      }}
    >
      <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: "16px",
          width: 64,
          height: 64,
        }}
      />
    </header>
  );
};

export default Header;















// import DropdownMessage from './DropdownMessage';
// import DropdownNotification from './DropdownNotification';
// import DropdownUser from './DropdownUser';
// import React from "react";
// import SEARCH_ICON from "../assets/icons/search-normal.png"
// import DASHBOARD_ICON from "../assets/icons/sort.png"


// const Header = (props: {
//     sidebarOpen: string | boolean | undefined;
//     setSidebarOpen: (arg0: boolean) => void;
// }) => {
//     return (
//         <header className="sticky top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
//             <div className="flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11">
//                 <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
//                     {/* <!-- Hamburger Toggle BTN for mobile --> */}
//                     <button
//                         aria-controls="sidebar"
//                         onClick={(e) => {
//                             e.stopPropagation();
//                             props.setSidebarOpen(!props.sidebarOpen);
//                         }}
//                         className="z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
//                     >
//             <span className="relative block h-5.5 w-5.5 cursor-pointer">
//               <span className="du-block absolute right-0 h-full w-full">
//                 <span
//                     className={`relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-[0] duration-200 ease-in-out dark:bg-white ${
//                         !props.sidebarOpen && '!w-full delay-300'
//                     }`}
//                 ></span>
//                 <span
//                     className={`relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out dark:bg-white ${
//                         !props.sidebarOpen && 'delay-400 !w-full'
//                     }`}
//                 ></span>
//                 <span
//                     className={`relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out dark:bg-white ${
//                         !props.sidebarOpen && '!w-full delay-500'
//                     }`}
//                 ></span>
//               </span>
//               <span className="absolute right-0 h-full w-full rotate-45">
//                 <span
//                     className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white ${
//                         !props.sidebarOpen && '!h-0 !delay-[0]'
//                     }`}
//                 ></span>
//                 <span
//                     className={`delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out dark:bg-white ${
//                         !props.sidebarOpen && '!h-0 !delay-200'
//                     }`}
//                 ></span>
//               </span>
//             </span>
//                     </button>
//                 </div>
//                 <div className=" flex items-center">
//                     <div  className="top-3 w-10 h-10 flex-shrink-0 cursor-pointer" >
//                         <img src={DASHBOARD_ICON} alt="" className="w-full h-full" />
//                     </div>
//                     <p className="ml-2 text-black font-Poppins font-medium text-xl leading-35">
//                         Dashboard
//                     </p>
//                 </div>

//                 <div className="relative flex items-center">
//                     <input
//                         type="text"
//                         className="border-2 border-gray-400 rounded-md py-2 pl-10 pr-4 w-96 h-12 opacity-60 focus:outline-none"
//                         placeholder="Search for services"
//                     />
//                     <div
//                         className="absolute left-3 top-3 h-6 w-6 text-gray-400"
//                     >
//                         <img src={SEARCH_ICON} alt=""/>

//                     </div>
//                 </div>

//                 <div className="flex items-center gap-3 2xsm:gap-7">
//                     <ul className="flex items-center gap-2 2xsm:gap-4">
//                         <DropdownNotification/>
//                         <DropdownMessage/>
//                     </ul>
//                     <DropdownUser/>
//                 </div>
//             </div>
//         </header>
//     );
// };

// export default Header;
